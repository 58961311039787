@import "bootstrap-sprockets";
@import "bootstrap";
@import "pygments-manni";

h1 {
  border-bottom: 1px solid #777;
  margin-bottom: 1em;
}

body {
  padding-top: 50px;
  font-size: 18px;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}

.address {
  text-align: center;
}

.date {
  font-size: 14px;
  position: absolute;
  bottom: 0.5em;
  right: 0px;
}

.img-side {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.img-rounded {
  margin-left: 1em;
}

.img-thumbnail {
  height: 320px;
  margin: 0 auto;
}

.navbar-brand {
  color: #fff !important;
}

.section {
  text-align: justify;
  padding-top: 30px;
  margin-top: -30px;
}

.title {
  position: relative;
}
